import React, { useCallback, useEffect, useRef, useState } from 'react'

import Header from './Components/Header'
import Footer from './Components/Footer'
import ChatConversation from './Components/ChatConversation'
import ProductSuggestion from './Components/ProductSuggestion'
import VirtualAgent from './Components/VirtualAgent'
import ChatInput from './Components/ChatInput'
import useConversationManager from './Components/Shared/useConversationManager'
import ChatStatus from './Components/ChatStatus'
import useStatusManager from './Components/Shared/useStatusManager'

function App () {
  const { conversation, addUserMessage, addAgentMessage, getAgentAnswer, getTextFromAudio, clearChat, getProductAnswer } = useConversationManager()
  const { status, startLoading, startCall, endCall } = useStatusManager()
  const [lastAgentMessage, setLastAgentMessage] = useState(null)
  const [isWaitingAgent, setIsWaitingAgent] = useState(false)
  const [productList, setProductList] = useState([])
  const currentQuestion = useRef(null)

  const onUserInput = useCallback(message => {
    addUserMessage(message)
    setIsWaitingAgent(true)
    currentQuestion.current = message
    getAgentAnswer(message).then(response => {
      if (currentQuestion.current !== response.customerMessage) return
      if (response.products) setProductList(response.products)
      setLastAgentMessage(response)
    })
  }, [addUserMessage, getAgentAnswer])

  const onUserAudio = useCallback(audio => {
    setIsWaitingAgent(true)
    getTextFromAudio(audio).then(response => {
      if (response?.text) onUserInput(response.text)
    })
  }, [getTextFromAudio, onUserInput])

  const onAgentAnswer = useCallback(message => {
    addAgentMessage(message)
    setIsWaitingAgent(false)
  }, [addAgentMessage])

  const getInitialMessage = useCallback(() => {
    clearChat()
    // startCall('Online')
    startLoading('Loading')
    getAgentAnswer('').then(response => {
      setLastAgentMessage(response)
    })
  }, [clearChat, getAgentAnswer, startLoading])

  const finishCall = useCallback(() => {
    setLastAgentMessage(null)
    endCall('Call ended')
  }, [endCall])

  const onAgentReady = useCallback(() => {
    startCall('Online')
  }, [startCall])

  const onProductSelect = useCallback(name => {
    setIsWaitingAgent(true)
    currentQuestion.current = name
    getProductAnswer(name).then(response => {
      if (currentQuestion.current !== response.productName) return
      if (response.products) setProductList(response.products)
      setLastAgentMessage(response)
    })
  }, [getProductAnswer])

  useEffect(() => {
    setLastAgentMessage(null)
  }, [])

  return (
    <>
      <Header />
      <div className='container flex-grow-1 overflow-hidden'>
        <div className='row h-100'>
          <div className='col d-flex flex-column h-100 py-3'>
            <ProductSuggestion
              producList={productList}
              onProductSelect={onProductSelect}
            />
          </div>
          {
          (status?.online || status?.loading)
            ? (
              <>
                <div className='col-auto d-flex flex-column justify-content-end h-100 py-3'>
                  <VirtualAgent
                    newMessage={lastAgentMessage}
                    onAnswerStart={onAgentAnswer}
                    onAgentReady={onAgentReady}
                  />
                </div>
              </>
              )
            : (
              <div className='col-5 d-flex flex-column justify-content-center align-items-center h-100 py-3'>
                <div className='card-container p-3'>
                  <p className='text-secondary'>Welcome to IRG Virtual Sales Agent</p>
                  <div className='text-center'>
                    <button
                      className='btn btn-primary'
                      onClick={() => getInitialMessage()}
                    >
                      Start new chat
                    </button>
                  </div>
                </div>
              </div>
              )
          }
          <div className='col d-flex flex-column h-100 py-3'>
            <ChatStatus
              status={status}
              onCallEnded={() => finishCall()}
            />
            <ChatConversation
              conversation={conversation}
              agentLoading={isWaitingAgent}
            />
            <ChatInput
              status={status}
              onNewMessage={message => onUserInput(message)}
              onNewAudio={blob => onUserAudio(blob)}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default App
