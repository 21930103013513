import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { FaMicrophone, FaPaperPlane, FaPlay, FaTimes } from 'react-icons/fa'

const MIN_AUDIO_SIZE = 5000

const AudioRecorder = (props) => {
  const { className, disabled, onRecordingChange, onBlobSend } = props
  const [isRecording, setIsRecording] = useState(false)
  const [audioBlob, setAudioBlob] = useState(null)
  const recorderRef = useRef()
  const audioRef = useRef(null)

  const isMediaSupported = useCallback(() => !!navigator.mediaDevices.getUserMedia, [])

  const startRecording = useCallback(() => {
    setAudioBlob(null)
    setIsRecording(true)
    recorderRef.current?.start()
  }, [])

  const stopRecording = useCallback(() => {
    recorderRef.current?.stop()
  }, [])

  const getAudioUrl = useCallback(blob => {
    return window.URL.createObjectURL(blob)
  }, [])

  const sendBlob = useCallback(() => {
    onBlobSend?.(audioBlob)
    setAudioBlob(null)
  }, [audioBlob, onBlobSend])

  useEffect(() => {
    onRecordingChange(isRecording || audioBlob)
  }, [isRecording, audioBlob, onRecordingChange])

  useEffect(() => {
    if (isRecording) document.addEventListener('mouseup', stopRecording)

    return () => {
      document.removeEventListener('mouseup', stopRecording)
    }
  }, [isRecording, stopRecording])

  useEffect(() => {
    if (!isMediaSupported) return

    const onSuccess = (stream) => {
      // console.log('READY', stream)
      recorderRef.current = new MediaRecorder(stream)

      recorderRef.current.ondataavailable = (e) => {
        setIsRecording(false)
        if (e.data.size < MIN_AUDIO_SIZE) return
        setAudioBlob(new Blob([e.data], { type: 'audio/ogg; codecs=opus' }))
      }
    }

    const onError = (err) => console.log('The following error occured: ' + err)

    navigator.mediaDevices.getUserMedia({ audio: true }).then(onSuccess, onError)
  }, [isMediaSupported])

  return (isMediaSupported())
    ? (
      <div className={`audio-recorder d-flex flex-grow-1 ${className}`}>
        {
        (audioBlob)
          ? (
            <>
              <audio ref={audioRef}>
                <source src={getAudioUrl(audioBlob)} type='audio/ogg' />
              </audio>
              <button
                className='btn btn-danger text-nowrap d-flex align-items-center'
                onClick={() => setAudioBlob(null)}
                title='Cancel'
              >
                Cancel <FaTimes className='ml-2' />
              </button>
              <button
                className='btn btn-secondary text-nowrap d-flex align-items-center ml-3'
                onClick={() => audioRef.current?.play()}
                title='Play audio'
              >
                Replay <FaPlay className='ml-2' />
              </button>
              <div className='flex-grow-1' />
              <button
                className='btn btn-primary text-nowrap d-flex align-items-center ml-3'
                onClick={() => sendBlob()}
                title='Send'
              >
                Send <FaPaperPlane className='ml-2' />
              </button>
            </>
            )
          : (
            <>
              <div className='flex-grow-1' />
              <button
                className={`btn ${(!disabled) ? (isRecording) ? 'btn-danger' : 'btn-primary' : 'btn-secondary'} text-nowrap line-height-1 ml-3 p-2 rounded-circle`}
                onMouseDown={(e) => { if (e.button === 0) startRecording() }}
                disabled={disabled}
              >
                <FaMicrophone size='20' />
              </button>
            </>
            )
        }
      </div>
      )
    : ''
}

AudioRecorder.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onRecordingChange: PropTypes.func,
  onBlobSend: PropTypes.func
}
AudioRecorder.defaultProps = {
  className: ''
}

export default AudioRecorder
