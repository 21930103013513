import React from 'react'
import { FaRobot } from 'react-icons/fa'

const HeaderMenu = () => {
  return (
    <div className='d-flex'>
      <FaRobot size='19' />
    </div>
  )
}

export default HeaderMenu
