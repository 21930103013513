import React from 'react'
import HeaderMenu from './HeaderMenu'

const Header = () => {
  return (
    <div className='bg-purple'>
      <nav className='text-white container d-flex py-2 align-items-center'>
        <div className='header-logo py-1 pr-3'>
          <HeaderMenu />
        </div>
        <div
          className='small flex-grow-1 py-1 cursor-pointer' onClick={() => {
            window.location.hash = '#/'
          }}
        >
          IRG Realtime Virtual Sales Agent
        </div>
      </nav>
    </div>
  )
}

export default Header
