import React, { useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import AudioRecorder from './AudioRecorder'

const ChatInput = (props) => {
  const { status, onNewMessage, onNewAudio } = props
  const [isRecording, setIsRecording] = useState(false)
  const inputRef = useRef(null)

  const sendNewMessage = useCallback(message => {
    if (!message || message.length === 0) return
    onNewMessage?.(message)
    inputRef.current.value = ''
  }, [onNewMessage])

  return (
    <div className='chat-input d-flex mt-3'>
      {!isRecording && (
        <>
          <input
            ref={inputRef}
            type='text'
            className='form-control'
            autoFocus
            disabled={!status?.online}
            onKeyDown={(e) => { if (e.key === 'Enter') sendNewMessage(inputRef.current.value) }}
          />
          <button
            className={`btn ${(status?.online) ? 'btn-primary' : 'btn-secondary'} text-nowrap ml-3`}
            onClick={() => sendNewMessage?.(inputRef.current.value)}
            disabled={!status?.online}
          >
            Send message
          </button>
        </>
      )}
      <AudioRecorder
        className=''
        disabled={!status?.online}
        onRecordingChange={value => setIsRecording(value)}
        onBlobSend={onNewAudio}
      />
    </div>
  )
}

ChatInput.propTypes = {
  status: PropTypes.object,
  onNewMessage: PropTypes.func,
  onNewAudio: PropTypes.func
}

export default ChatInput
