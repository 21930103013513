import { useCallback, useReducer } from 'react'

const API_URL = 'https://va.irgrealtimeaiservice.com'
const API_KEY = 'YWRtaW46aXJncmVhbHRpbWU='

const useConversationManager = () => {
  const conversationReducer = (state, action) => {
    switch (action.type) {
      case 'add':
        return [...state, action.payload]
      case 'clear':
        return []
      default:
        return state
    }
  }
  const [conversation, dispatch] = useReducer(conversationReducer, [])

  const blobToBase64 = useCallback(async (blob) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    return new Promise(resolve => {
      reader.onload = () => {
        resolve(reader.result)
      }
    })
  }, [])

  const addUserMessage = useCallback(message => {
    dispatch({
      type: 'add',
      payload: {
        sender: 'customer',
        timeStamp: new Date(),
        message
      }
    })
  }, [])

  const addAgentMessage = useCallback((message, timeStamp) => {
    dispatch({
      type: 'add',
      payload: {
        sender: 'agent',
        timeStamp: (timeStamp) ? new Date(timeStamp) : new Date(),
        message
      }
    })
  }, [])

  const clearChat = useCallback(() => {
    dispatch({
      type: 'clear'
    })
  }, [])

  const getAgentAnswer = useCallback(async (customerMessage) => {
    return fetch(API_URL + '/api', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + API_KEY
      },
      body: JSON.stringify({
        utterance: customerMessage
      })
    })
      .then(response => response.json())
      .then(response => ({ ...response, customerMessage }))
  }, [])

  const getTextFromAudio = useCallback(async (audio) => {
    const convertedAudio = await blobToBase64(audio)
    return fetch(API_URL + '/audiototext', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + API_KEY
      },
      body: JSON.stringify({
        message: convertedAudio.split(',')[1]
      })
    }).then(response => response.json())
  }, [blobToBase64])

  const getProductAnswer = useCallback(async (productName) => {
    return fetch(API_URL + '/api', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + API_KEY
      },
      body: JSON.stringify({
        utterance: '',
        user_action: {
          name: '',
          label: productName
        }
      })
    })
      .then(response => response.json())
      .then(response => ({ ...response, productName }))
  }, [])

  return {
    conversation,
    addUserMessage,
    addAgentMessage,
    getAgentAnswer,
    clearChat,
    getTextFromAudio,
    getProductAnswer
  }
}

export default useConversationManager
