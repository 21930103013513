import React from 'react'
import PropTypes from 'prop-types'
import { IoFileTrayOutline } from 'react-icons/io5'

import './ProductSuggestion.scss'

const ProductSuggestion = (props) => {
  const { producList, onProductSelect } = props

  return (
    <div className='product-suggestion overflow-hidden flex-grow-1 d-flex flex-column pl-2 ml-n2'>
      <h5 className='mb-3'>Product Suggestions</h5>
      <div className='product-suggestion-body px-3 ml-n3 flex-grow-1'>
        <div className={`row pt-2 h-100 ${(producList?.length) ? 'align-content-start' : 'align-content-center'}`}>
          {(producList?.length)
            ? producList.map((product, index) => (
              <div key={index} className='product-frame col-6 col-lg-3 col-xl-4 px-3 mb-3 d-flex flex-column '>
                <div
                  className='product-image'
                  style={{ backgroundImage: (product.product_attributes?.link) ? 'url(' + product.product_attributes.link + ')' : '' }}
                  title={product.product_attributes?.product_long_name || product.product_name}
                />
                <div className='flex-grow-1 product-info overflow-hidden mt-2'>
                  <p
                    className='product-name font-weight-bold mb-1 border-bottom pb-1 text-truncate'
                    title={product.product_attributes?.product_long_name || product.product_name}
                  >{product.product_name}
                  </p>
                  <p className='product-desc small' title={product.product_attributes?.description}>{product.product_attributes?.description}</p>
                </div>
                <div className='text-center'>
                  <button className='btn btn-primary btn-sm' onClick={() => onProductSelect?.(product.product_name)}>Ask for details</button>
                </div>
              </div>
            ))
            : (
              <div className='product-empty col text-center p-3 align-self-center'>
                <div className='h2 text-secondary'>
                  <IoFileTrayOutline />
                </div>
                Products will appear as the conversation starts
              </div>
              )}
        </div>
      </div>
    </div>
  )
}

ProductSuggestion.propTypes = {
  producList: PropTypes.array,
  onProductSelect: PropTypes.func
}

export default ProductSuggestion
