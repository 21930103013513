import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Unity, { UnityContext } from 'react-unity-webgl'
import './VirtualAgent.scss'

const unityContext = new UnityContext({
  loaderUrl: 'UnityBuild/Build/webgl.loader.js',
  dataUrl: 'UnityBuild/Build/webgl.data.unityweb',
  frameworkUrl: 'UnityBuild/Build/webgl.framework.js.unityweb',
  codeUrl: 'UnityBuild/Build/webgl.wasm.unityweb'
})

const VirtualAgent = (props) => {
  const { newMessage, onAnswerStart, onAnswerDone, onAgentReady } = props
  const answerAudioRef = useRef(null)
  const [isUnityLoaded, setIsUnityLoaded] = useState(false)
  const [agentProgress, setAgentProgress] = useState(0)
  // const [isAnswerPlaying, setIsAnswerPlayer] = useState(false)

  useEffect(() => {
    setIsUnityLoaded(false)
    let delay = null

    // ToDo: Investigate if there is a "init" event to replace the logo delay in the loaded
    unityContext.on('loaded', () => {
      onAgentReady?.()
      delay = setTimeout(() => {
        setIsUnityLoaded(true)
      }, 3200)
    })
    unityContext.on('progress', (value) => {
      setAgentProgress(value * 100)
    })

    return () => {
      unityContext.removeAllEventListeners()
      if (delay) clearTimeout(delay)
    }
  }, [onAgentReady])

  useEffect(() => {
    if (!isUnityLoaded || !newMessage?.audio) return
    const audioElement = answerAudioRef.current

    const sendLipsync = (visemes) => {
      unityContext?.send('AvatarReceiver', 'Lipsync', JSON.stringify({ visemes }))
    }

    const onAudioReady = (e) => {
      onAnswerStart?.(newMessage.text)
      if (newMessage.visemes) sendLipsync(newMessage.visemes)
      audioElement.play()
    }

    const onAudioPlaying = (e) => {
      // setIsAnswerPlayer(true)
    }

    const onAudioEnded = (e) => {
      onAnswerDone?.(newMessage.text)
      // setIsAnswerPlayer(false)
    }

    audioElement.src = `data:audio/mp3;base64,${newMessage.audio}`
    audioElement.addEventListener('canplaythrough', onAudioReady)
    audioElement.addEventListener('playing', onAudioPlaying)
    audioElement.addEventListener('ended', onAudioEnded)
    audioElement.load()
    return () => {
      audioElement.removeEventListener('canplaythrough', onAudioReady)
      audioElement.removeEventListener('playing', onAudioPlaying)
      audioElement.removeEventListener('ended', onAudioEnded)
    }
  }, [isUnityLoaded, newMessage, onAnswerDone, onAnswerStart])

  return (
    <div className='virtual-agent d-flex justify-content-center align-items-end'>
      <audio ref={answerAudioRef} />
      <div className='agent-video-container card-container overflow-hidden'>
        <Unity unityContext={unityContext} tabIndex={1} style={{ width: '100%', height: '100%' }} />
        {(agentProgress < 100) && (
          <div className='progress-container d-flex flex-column justify-content-center p-2'>
            <p className='mb-0 text-center'>Loading Virtual Sales Agent</p>
            <progress min='0' max='100' value={agentProgress} />
          </div>
        )}
      </div>
    </div>
  )
}

VirtualAgent.propTypes = {
  newMessage: PropTypes.object,
  onAnswerStart: PropTypes.func,
  onAnswerDone: PropTypes.func,
  onAgentReady: PropTypes.func
}

export default VirtualAgent
