import { useCallback, useReducer } from 'react'

const INIT_STATUS = { message: 'Offline', online: false, loading: false }

const useStatusManager = () => {
  const statusReducer = (state, action) => {
    switch (action.type) {
      case 'loading':
        return { message: action.message, online: false, loading: true }
      case 'start':
        return { message: action.message, online: true, loading: false }
      case 'end':
        return { message: action.message, online: false, loading: false }
      default:
        return state
    }
  }
  const [status, dispatch] = useReducer(statusReducer, INIT_STATUS)

  const startLoading = useCallback(message => {
    dispatch({
      type: 'loading',
      message
    })
  }, [])

  const startCall = useCallback(message => {
    dispatch({
      type: 'start',
      message
    })
  }, [])

  const endCall = useCallback(message => {
    dispatch({
      type: 'end',
      message
    })
  }, [])

  return {
    status,
    startLoading,
    startCall,
    endCall
  }
}

export default useStatusManager
