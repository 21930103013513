import React, { useCallback, useEffect, useRef } from 'react'

import PropTypes from 'prop-types'
import moment from 'moment'

import './ChatConversation.scss'
import Spinner from './Spinner'

const ChatConversation = (props) => {
  const { conversation, agentLoading } = props
  const chatWindowRef = useRef(null)

  const formatTimeStamp = useCallback(date => {
    return moment(date).format('h:mm a, MMMM Do YYYY')
  }, [])

  useEffect(() => {
    if (chatWindowRef.current) chatWindowRef.current.scrollTop = chatWindowRef.current?.scrollHeight - chatWindowRef.current?.clientHeight
  }, [conversation])

  return (
    <div className='card-container chat-conversation d-flex flex-column flex-grow-1 overflow-auto mt-3' ref={chatWindowRef}>
      {conversation.map((item, index) => (
        <div key={index} className={`${item.sender === 'agent' ? 'agent-box' : 'customer-box'} conversation-box`}>
          <div className='chatbox'>{item.message}</div>
          <div className='timeStamp'>{formatTimeStamp(item.timeStamp)}</div>
        </div>
      ))}
      {agentLoading && (
        <div className='text-right'>
          <Spinner className='text-primary' />
        </div>
      )}
    </div>
  )
}

ChatConversation.propTypes = {
  conversation: PropTypes.array,
  agentLoading: PropTypes.bool
}

export default ChatConversation
