import React from 'react'
import AccentureLogo from '../Images/Acc_Logo_Black_Purple_RGB.png'
import IRGLogo from '../Images/A20.png'

const Footer = () => {
  return (
    <div className='border-top py-1 small shadow-lg bg-white'>
      <div className='container d-flex'>
        <img src={AccentureLogo} className='mr-5 align-self-center' alt='Accenture' />
        <div className='mr-5 align-self-center'>
          2021 Accenture. All rights reserved.
        </div>
        <div className='mr-4 align-self-center cursoPointer slowTransition footerLink'>
          Terms of Use
        </div>
        <div className='mr-4 align-self-center cursoPointer slowTransition footerLink'>
          Privacy statement
        </div>
        <div className='align-self-center cursoPointer slowTransition footerLink flex-fill'>
          Contact Support
        </div>
        <img src={IRGLogo} className='align-self-center' alt='Intelligent Revenue Growth' />
      </div>
    </div>
  )
}

export default Footer
