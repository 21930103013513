import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { FiPhone, FiPhoneCall } from 'react-icons/fi'

import './ChatStatus.scss'

const ChatStatus = (props) => {
  const { status, onCallEnded } = props
  const [initTime, setInitTime] = useState(moment())
  const [currentTime, setCurrentTime] = useState(moment())

  const getTimeDiff = useCallback(() => {
    return moment(currentTime.diff(initTime)).format('mm:ss')
  }, [currentTime, initTime])

  useEffect(() => {
    if (!status?.online) return
    setInitTime(moment())
    setCurrentTime(moment())
    const updateTimer = setInterval(() => {
      setCurrentTime(moment())
    }, 1000)

    return () => {
      clearInterval(updateTimer)
    }
  }, [status?.online])

  return (
    <div className='chat-status card-container d-flex align-items-center py-3 px-2'>
      <button
        className={`btn rounded-circle p-2 line-height-1 mr-3 ${(status?.online ? 'btn-danger' : 'btn-secondary')}`}
        onClick={() => onCallEnded?.()}
        disabled={!status?.online}
      >
        {(status?.online) ? (<FiPhoneCall size='18' />) : (<FiPhone size='18' />)}
      </button>
      <div className='flex-grow-1'>
        <h6 className='my-0'>Status: {status?.message || ''}</h6>
        <div className='call-duration'>{getTimeDiff()} MIN</div>
      </div>
    </div>
  )
}

ChatStatus.propTypes = {
  status: PropTypes.object,
  onCallEnded: PropTypes.func
}

export default ChatStatus
